import ModalPortal from '@/components/ModalPortal/ModalPortal';
import capitalize from '@/helpers/capitalize';
import convertGermanFormatToNumber from '@/helpers/convertGermanFormatToNumber';
import { FormatDateType, formatDate } from '@/helpers/date';
import Collapsible from '@uikit/components//Collapsible/Collapsible';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import ProductOverview from '@uikit/components/ProductOverview/ProductOverview';
import {
  ProductOverviewType,
  StepColor,
} from '@uikit/components/ProductOverview/consts';
import ProductOverviewModalContent from '@uikit/components/ProductOverviewModalContent/ProductOverviewModalContent';
import RegioFormula from '@uikit/components/RegioFormula/RegioFormula';
import RegioFormulaItem from '@uikit/components/RegioFormula/RegioFormulaItem/RegioFormulaItem';
import RegioFormulaSeparator from '@uikit/components/RegioFormula/RegioFormulaSeparator/RegioFormulaSeparator';
import RegioTariffComparison from '@uikit/components/RegioTariffComparison/RegioTariffComparison';
import Spacer from '@uikit/components/Spacer/Spacer';
import { getPricePerMonth } from '@uikit/deprecated-helpers/getPricePerMonth';
import { getPricePerYear } from '@uikit/deprecated-helpers/getPricePerYear';
import getProductDetailsString from '@uikit/deprecated-helpers/getProductDetailsString';
import { formatCurrency } from '@uikit/helpers/formatNumber';
import getCheapestTariff from '@uikit/helpers/getCheapestTariff';
import getTariffSavingsAmount from '@uikit/helpers/getTariffSavingsAmount';
import hasSufficientSavingsAmount from '@uikit/helpers/isTariffCheaperThanBaseProvider';
import productTypeDictionary from '@uikit/helpers/productTypeDictionary';
import { useState } from 'react';
import styles from './TariffComparison.module.scss';
import { TariffComparisonProps } from './types';

const isEnercityBaseProvider = (providerName: string) => {
  return providerName.toLocaleLowerCase().includes('enercity');
};

const TariffComparison = ({
  configuratorUrl,
  consumption,
  lastUpdated,
  productType,
  tariffResponseData,
  fallbackTariffs,
  savingsThreshold,
  zipCode,
}: TariffComparisonProps) => {
  const [selectedTariffId, setSelectedTariffId] = useState<string>('');
  const { tariffs, baseProviderTariff } =
    tariffResponseData?.[productType] ?? {};
  const tariffsOrFallback = tariffs?.length ? tariffs : fallbackTariffs;
  const cheapestTariff = getCheapestTariff(tariffs);
  const selectedTariff = [...(tariffsOrFallback ?? [])].find(
    (tariff) => tariff.id === selectedTariffId
  );

  const savingsAmount = getTariffSavingsAmount(
    cheapestTariff,
    baseProviderTariff
  );

  const formattedSavingsAmount = formatCurrency(savingsAmount);

  const isCheaperThanBaseProvider = hasSufficientSavingsAmount(
    savingsAmount,
    savingsThreshold
  );

  const handleClickDetails = (id: string) => () => {
    setSelectedTariffId(id);
  };

  const handleOnClose = () => {
    setSelectedTariffId('');
  };

  const lastUpdatedTariffDate =
    lastUpdated && formatDate(new Date(lastUpdated), FormatDateType.Long);

  const hasTariffPrices = selectedTariff && 'prices' in selectedTariff;

  return (
    <div className={styles.base}>
      {tariffs &&
      isCheaperThanBaseProvider &&
      baseProviderTariff &&
      !isEnercityBaseProvider(baseProviderTariff.providerName) ? (
        <>
          <RegioTariffComparison
            baseProviderTariff={{
              tariffName: baseProviderTariff?.tariffName ?? '',
              providerName: baseProviderTariff?.providerName ?? '',
              price: formatCurrency(
                convertGermanFormatToNumber(baseProviderTariff?.yearlyPrice) /
                  12
              ),
            }}
            enercityTariff={{
              id: cheapestTariff.id,
              tariffName: cheapestTariff.name,
              providerName: 'enercity AG',
              price: formatCurrency(
                convertGermanFormatToNumber(cheapestTariff.prices.monthly)
              ),
            }}
            savingsAmount={formattedSavingsAmount}
            handleOnClickDetails={handleClickDetails(cheapestTariff.id)}
            configuratorUrl={configuratorUrl}
          />
          <Spacer defaultSize={2} />
          <Collapsible
            textOpen="* So haben wir gerechnet"
            textClosed="* So haben wir gerechnet"
            collapseDirection="bottom"
            iconPosition="right"
          >
            <Spacer defaultSize={2} />
            <RegioFormula>
              <RegioFormulaItem>Arbeitspreis</RegioFormulaItem>
              <RegioFormulaSeparator>&times;</RegioFormulaSeparator>
              <RegioFormulaItem>Verbrauch</RegioFormulaItem>
              <RegioFormulaSeparator>+</RegioFormulaSeparator>
              <RegioFormulaItem>Grundpreis für 12 Monate</RegioFormulaItem>
              <RegioFormulaSeparator>=</RegioFormulaSeparator>
              <RegioFormulaItem>Vergleichspreis</RegioFormulaItem>
            </RegioFormula>
            <p className={styles.footnote}>
              {`*Verglichen wurde hier der ${capitalize(
                productTypeDictionary[productType]
              )}tarif "${cheapestTariff.name}" in der
            Postleitzahl ${zipCode} mit dem ${capitalize(
              productTypeDictionary[productType]
            )}tarif "${baseProviderTariff?.tariffName}", bei einem durchschnittlichem Verbrauch von ${consumption} kWh
            und dem lokalen Grundversorger, ${baseProviderTariff?.providerName}. Der
            Grundversorger bietet möglicherweise auch günstigere Tarife an,
            unabhängig von dem hier genannten Grundversorgungstarif.`}
            </p>
            {lastUpdatedTariffDate && (
              <p className={styles.footnoteDate}>
                Die Preise wurden zuletzt am {lastUpdatedTariffDate} Uhr
                aktualisiert.
              </p>
            )}
          </Collapsible>
        </>
      ) : (
        <div className={styles.productOverviewWrapper}>
          {tariffsOrFallback?.map((tariff) => (
            <ProductOverview
              key={tariff.id}
              productOverviewType={ProductOverviewType.PowerAndGas}
              headlineFirst={tariff.name}
              headlineSecond={tariff.information.subtitle}
              icon={tariff.icon}
              isWithBoxComponent={true}
              onClickDetails={handleClickDetails(tariff.id)}
              tariffId={tariff.id}
              details={getProductDetailsString(
                tariff.information.shortDescription
              )}
              price={
                'prices' in tariff && tariff.prices
                  ? `${getPricePerMonth(tariff)}€`
                  : undefined
              }
              detailInfoText="Tarifdetails anzeigen"
              actionType={ClickableAction.Linkextern}
              linkextern={configuratorUrl}
              buttonText="Jetzt wechseln"
              badge={tariff.information?.badge}
            />
          ))}
        </div>
      )}
      <ModalPortal
        id={'product-overview-modal'}
        onClose={handleOnClose}
        show={!!selectedTariffId}
        variant="shade"
        disableCloseIcon
        contentCentered
      >
        {selectedTariff && (
          <ProductOverviewModalContent
            onClose={handleOnClose}
            productOverviewType={ProductOverviewType.PowerAndGas}
            price={
              hasTariffPrices && selectedTariff.prices
                ? `${getPricePerMonth(selectedTariff)}€`
                : undefined
            }
            priceComment={
              hasTariffPrices && selectedTariff.prices ? (
                selectedTariff.bonus ? (
                  <>
                    inkl. Bonus pro Monat im 1. Jahr
                    <br />
                    {selectedTariff.prices.monthly} € Abschlag ohne Bonus
                  </>
                ) : (
                  'pro Monat'
                )
              ) : null
            }
            actionType={ClickableAction.None}
            stepColor={StepColor.Turquoise}
            icon={selectedTariff.icon}
            headlineFirst={selectedTariff.name || ''}
            headlineSecond={selectedTariff.information.subtitle}
            details={getProductDetailsString(
              selectedTariff.information.shortDescription || []
            )}
            priceExtension={
              hasTariffPrices && selectedTariff.prices
                ? {
                    infos: [
                      {
                        label: 'Arbeitspreis pro kWh',
                        price: `${selectedTariff.prices.unit} Cent`,
                      },
                      {
                        label: 'Grundpreis pro Monat',
                        price: `${selectedTariff.prices.base} €`,
                      },
                      ...(!selectedTariff.bonus
                        ? [
                            {
                              label: 'Gesamtpreis pro Jahr',
                              price: `${selectedTariff.prices?.yearly} €`,
                            },
                          ]
                        : []),
                      ...(selectedTariff.bonus
                        ? [
                            {
                              label: 'Sofortbonus',
                              price: `- ${selectedTariff.bonus.amount} €`,
                            },
                          ]
                        : []),
                      ...(selectedTariff.bonus
                        ? [
                            {
                              label: 'Gesamtpreis im 1. Jahr',
                              price: `${getPricePerYear(selectedTariff)} €`,
                            },
                          ]
                        : []),
                      ...(selectedTariff.bonus
                        ? [
                            {
                              label: 'Gesamtpreis ab dem 2. Jahr',
                              price: `${selectedTariff.prices?.yearly} €`,
                            },
                          ]
                        : []),
                    ],
                    footnote: `Alle Preise inkl. ${selectedTariff.taxRate} % Umsatzsteuer`,
                  }
                : undefined
            }
            modalRightSide={selectedTariff.information.moreInfo}
            attachments={selectedTariff.attachments ?? []}
          />
        )}
      </ModalPortal>
    </div>
  );
};

export default TariffComparison;
