import { EnercityTariffsVersion } from '@/models/tariff';
import { formatNumberWithFraction } from '@uikit/helpers/formatNumber';
import type { SuGRTariffsResponseData } from './types';

export const transformSuGRProduct = (
  response: SuGRTariffsResponseData,
  productType: 'gas' | 'power'
) => {
  if (!response?.[productType]?.tariffs) {
    return;
  }

  const {
    [productType]: { tariffs, baseProvider, ...rest },
  } = response;

  return {
    tariffs: tariffs?.map(
      ({
        id,
        information: {
          icon,
          bullets,
          descriptionParagraphs,
          displayName,
          displayProductName,
          sort,
        },
        source,
        platformSpecificData,
        prices,
        includedBonus,
        taxes,
      }) => ({
        name: displayName,
        information: {
          moreInfo: descriptionParagraphs?.reduce(
            (result, { content, title }) => {
              return `${result}<p><strong>${title}</strong><br />${content}<br/><br/></p>`;
            },
            ''
          ),
          subtitle: displayProductName,
          shortDescription: bullets,
          ...(includedBonus
            ? {
                badge: `Inkl. ${formatNumberWithFraction(includedBonus.amount)} € Bonus`,
              }
            : {}),
        },
        ...(icon?.filename_download
          ? {
              icon: {
                path: `/assets/directus/${icon.filename_download}`,
                name: icon.title,
                ...(icon.width && { width: icon.width }),
                ...(icon.height && { height: icon.height }),
              },
            }
          : {}),
        newPlatformInfo: platformSpecificData?.ed4,
        id,
        platform:
          source?.platform === 'ED4'
            ? EnercityTariffsVersion.New
            : EnercityTariffsVersion.Old,
        prices: {
          monthly: formatNumberWithFraction(prices.monthly),
          yearly: formatNumberWithFraction(prices.annual),
          base: formatNumberWithFraction(prices.base),
          unit: formatNumberWithFraction(prices.unit),
        },
        productType,
        prio: sort?.toString(),
        taxRate: taxes?.rate,
      })
    ),
    ...(baseProvider && {
      baseProviderTariff: {
        providerName: baseProvider.providerName,
        tariffName: baseProvider.tariffName,
        yearlyPrice: formatNumberWithFraction(baseProvider.annualPrice),
      },
    }),
    ...rest,
  };
};
